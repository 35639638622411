import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../../store/AppContext';
import MappingsNetoMyobSchema from '../interactions/NetoToMyobMappingsSchema';
import { useFormik, FormikProvider, Form } from 'formik';
import {
    SalesOrderMappingsModel, ItemMappingsModel, SynchronizationFeaturesMappingsModel,
    TaxCodeMappingsModel, PaymentMappingsModel, ProductStockMappingsModel, WarehouseCommunicationModel,
    CustomerMappingsMyobToNetoModel, ExistingDataMappingsModel, CustomerMappingsNetoToMyobModel, UserMappingModel, WarehouseMappingsModel, SyncProcessingStatesModel
} from '../../../shared/models/mappings/UserMappingModels';
import { createBrowserHistory } from "history";
import _ from 'lodash';
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import NetoSalesOrderToMYOBInvoiceBasic from './components/NetoSalesOrderToMYOBInvoiceBasic';
import { getFormLookupsNetoToMyobMappings, getUserConnectorConfiguration, saveUserMappings, getMappingsInitialValues, validateUserMappingsAgainstFormLookups, evaluateWarehouseMappings, validateWarehouseMappings } from './services/MappingService';
import SyncronizationFeatures from './components/SyncronizationFeatures';
import NetoToMYOBTaxCodeMapping from './components/NetoToMYOBTaxCodeMapping';
import NetoToMYOBPaymentMapping from './components/NetoToMYOBPaymentMapping';
import NetoProductToMYOBItemMapping from './components/NetoProductToMYOBItemMapping';
import MYOBToNetoProductStockMapping from './components/MYOBToNetoProductStockMapping';
import WarehouseMapping from './components/WarehouseMapping';
import CustomerMapping from './components/CustomerMapping';
import ExistingData from './components/ExistingData';
import { FormLookupsRequestResponse } from './models/FormLookupsRequestResponse';
import { UserConnectorConfiguration } from './models/UserConnectorConfiguration';
import { writeInfo } from '../../../shared/services/LoggingService';
import './NetoToMyobMappingsContainer.css';

const NetoToMyobMappingsContainer: React.FC = () => {
    let { State, Dispatch } = useContext(AppContext);

    const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
        {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

    const { id, storeName } = useParams<any>();
    const [salesOrderMapping, setSalesOrderMapping] = useState<SalesOrderMappingsModel>();
    const [productMapping, setProductMapping] = useState<ItemMappingsModel>();
    const [syncFeatures, setSyncFeatures] = useState<SynchronizationFeaturesMappingsModel>();
    const [taxCodeMapping, setTaxCodeMapping] = useState<TaxCodeMappingsModel>();
    const [paymentMapping, setPaymentMapping] = useState<PaymentMappingsModel>();
    const [productStockMapping, setProductStockMapping] = useState<ProductStockMappingsModel>();
    const [warehouseMappings, setWarehoueMappings] = useState<Array<WarehouseCommunicationModel>>();
    const [myobCustomerToNeto, setMyobCustomerToNeto] = useState<CustomerMappingsMyobToNetoModel>(State.userMappings?.myobCustomerToNeto ?? { isChecked: false, syncAs: "", lastRunAt: "", syncErrorMessage: "" });
    const [mapExistingData, setMapExistingData] = useState<ExistingDataMappingsModel>();
    const [syncProcessingStatesData, setSyncProcessingStatesData] = useState<SyncProcessingStatesModel>();
    const [netoCustomerToMyob, setNetoCustomerToMyob] = useState<CustomerMappingsNetoToMyobModel>(State.userMappings?.netoCustomerToMyob ?? { isChecked: false, syncAs: "", lastRunAt: "" });
    const [isMappingsEmpty, setIsMappinsEmpty] = useState<boolean>(false);
    const [checkIfHeader1HasError, setcheckIfHeader1HasError] = useState<boolean>(false);
    const [checkIfHeader2HasError, setcheckIfHeader2HasError] = useState<boolean>(false);
    const [checkIfHeader3HasError, setcheckIfHeader3HasError] = useState<boolean>(false);
    const [checkIfHeader4HasError, setcheckIfHeader4HasError] = useState<boolean>(false);
    const [checkIfHeader5HasError, setcheckIfHeader5HasError] = useState<boolean>(false);
    const [checkIfHeader6HasError, setcheckIfHeader6HasError] = useState<boolean>(false);
    const [checkIfHeader7HasError, setcheckIfHeader7HasError] = useState<boolean>(false);
    const [checkIfHeader8HasError, setcheckIfHeader8HasError] = useState<boolean>(false);
    const [checkIfHeader9HasError, setcheckIfHeader9HasError] = useState<boolean>(false);
    const history = createBrowserHistory();
    const [refreshLookupState, setRefreshLookupState] = useState<boolean>(false);
    const [activeTabs, setActiveTabs] = useState<Array<number>>([0]);
    const [blockMappings, setBlockMappings] = useState<boolean>(false);

    const [lastModifiedDataOfMapping, setLastModifiedDataOfMapping] = useState<any>({});
    const [lastModifiedDateOfMapping, setLastModifiedDateOfMapping] = useState<any>("");

    const formik = useFormik({
        initialValues: {
            salesOrderMapping: !_.isEmpty(salesOrderMapping) ? salesOrderMapping : getMappingsInitialValues().SalesOrderMappings,
            productMapping: !_.isEmpty(productMapping) ? productMapping : getMappingsInitialValues().ProductMappings,
            productStockMapping: !_.isEmpty(productStockMapping) ? productStockMapping : getMappingsInitialValues().ProductStockMappings,
            synchronizationFeatures: !_.isEmpty(syncFeatures) ? syncFeatures : getMappingsInitialValues().SyncFeatures,
            paymentMapping: !_.isEmpty(paymentMapping) ? paymentMapping : getMappingsInitialValues().PaymentMappings,
            taxCodeMappings: !_.isEmpty(taxCodeMapping) ? taxCodeMapping : getMappingsInitialValues().TaxCodeMappings,
            // warehouseMappings: !_.isEmpty(warehouseMappings) ? warehouseMappings : getMappingsInitialValues().WarehouseMappings,
            myobCustomerToNeto: !_.isEmpty(myobCustomerToNeto) ? myobCustomerToNeto : getMappingsInitialValues().CustomerMyobToNeto,
            netoCustomerToMyob: !_.isEmpty(netoCustomerToMyob) ? netoCustomerToMyob : getMappingsInitialValues().CustomerNetoToMyob,
            mapExistingData: !_.isEmpty(mapExistingData) ? mapExistingData : getMappingsInitialValues().ExistingDataMappings,
            SyncProcessingStates: !_.isEmpty(syncProcessingStatesData) ? syncProcessingStatesData : getMappingsInitialValues().SyncProcessingStates,
        },
        validationSchema: MappingsNetoMyobSchema,
        enableReinitialize: true,
        // innerRef: formRef,
        onSubmit: () => {
            saveMappings();
        },
    });

    const onInputBlurred = (obj: any) => {
        formik.setTouched(obj, true);
    }

    const onWarehouseBlur = (warehouseMappings: any) => {
        const validatedWarehouseMappings = validateWarehouseMappings(warehouseMappings);
        setWarehoueMappings(validatedWarehouseMappings);
        let hasError = validatedWarehouseMappings.some((x) => x.isError === true);
        if (hasError) {
            if (activeTabs.includes(6))
                setActiveTabs(activeTabs);
            else {
                activeTabs.push(6);
                setActiveTabs(activeTabs);
            }
            setcheckIfHeader7HasError(true);
        } else {
            setcheckIfHeader7HasError(false);
        }
    }

    useEffect(() => {
        setBlockMappings(true);
        let netoToMyobFormLookupsRequestResult: FormLookupsRequestResponse = {} as FormLookupsRequestResponse;
        (async () => {
            netoToMyobFormLookupsRequestResult = await getFormLookupsNetoToMyobMappings(
                /*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId,
                id
            );
            if (netoToMyobFormLookupsRequestResult?.isSucceed) {
                Dispatch({
                    Type: "SET_FORMSLOOKUP",
                    Payload: netoToMyobFormLookupsRequestResult?.result,
                });
                // setBlockMappings(true);
            } else {
                setBlockMappings(false);
                Dispatch({
                    Type: "SET_REQUEST_STATUS",
                    Payload: {
                        isSucceed: false,
                        message: netoToMyobFormLookupsRequestResult?.message,
                    },
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (isMappingsEmpty === true) {
            setWarehoueMappings([]);
        }
    }, [isMappingsEmpty]);

    useEffect(() => {
        if (State.formsLookup && Object.keys(State.formsLookup).length > 0 && !refreshLookupState) {
            (async () => {
                let userMappings: UserConnectorConfiguration = {} as UserConnectorConfiguration;
                userMappings = await getUserConnectorConfiguration(/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId, id);
                let lastModifiedDataOfMapping: any = userMappings.result
                setLastModifiedDataOfMapping(lastModifiedDataOfMapping.lastModifiedData ?? {});
                setLastModifiedDateOfMapping(lastModifiedDataOfMapping.lastModified ?? "")
                if (userMappings.isSucceed) {
                    setIsMappinsEmpty(userMappings?.result?.isMappingsEmpty);
                    const validatedMappings = validateUserMappingsAgainstFormLookups(userMappings?.result, State.formsLookup);
                    setBlockMappings(false);
                    Dispatch({ Type: "SET_USER_MAPPINGS", Payload: validatedMappings }); //userMappings?.result?.mappings });
                } else {
                    setBlockMappings(false);
                    Dispatch({
                        Type: "SET_REQUEST_STATUS",
                        Payload: {
                            isSucceed: false,
                            message: "Some error occurred while getting User Mappings!",
                        },
                    });
                }
            })();
        } else {
            !refreshLookupState ? setBlockMappings(true) : setBlockMappings(false);
        }
    }, [State?.formsLookup])

    useEffect(() => {
        if (State.userMappings) {
            setSalesOrderMapping(State?.userMappings?.salesOrderMapping ?? {} as SalesOrderMappingsModel);
            setSyncFeatures(State?.userMappings?.synchronizationFeatures ?? {} as SynchronizationFeaturesMappingsModel);
            setTaxCodeMapping(State.userMappings?.taxCodeMappings ?? {} as TaxCodeMappingsModel);
            setPaymentMapping(State?.userMappings?.paymentMapping ?? {} as PaymentMappingsModel);
            setProductMapping(State.userMappings?.itemMapping ?? {} as ItemMappingsModel);
            setProductStockMapping(State.userMappings?.productStockMapping ?? {} as ProductStockMappingsModel);
            setMyobCustomerToNeto(State.userMappings?.myobCustomerToNeto ?? {} as CustomerMappingsMyobToNetoModel);
            setMapExistingData(State.userMappings?.existingData ?? {} as ExistingDataMappingsModel);
            setSyncProcessingStatesData(State.userMappings?.SyncProcessingStates ?? {} as SyncProcessingStatesModel);
            // setIsMappinsEmpty(!_.isEqual(State.userMappings, getMappingsInitialValues()));
            populateWarehouse();
        }
    }, [State.userMappings]);

    useEffect(() => {
        return () => {
            localStorage.removeItem("options");
            localStorage.removeItem("selectedOption");
        }
    }, []);

    useEffect(() => {
        if (formik.errors && Object.keys(formik.errors).length > 0) {
            if (formik.submitCount > 0 && ((formik.errors?.salesOrderMapping && Object.keys(formik.errors?.salesOrderMapping as any).length > 0) &&
                (formik.touched.salesOrderMapping && Object.keys(formik.touched?.salesOrderMapping as any).length > 0))) {
                if (activeTabs.includes(0))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(0);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader1HasError(true);
            }
            else {
                setcheckIfHeader1HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.synchronizationFeatures && Object.keys(formik.errors?.synchronizationFeatures as any).length > 0) &&
                (formik.touched?.synchronizationFeatures && Object.keys(formik.touched?.synchronizationFeatures as any).length > 0))) {
                if (activeTabs.includes(1))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(1);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader2HasError(true);
            }
            else {
                setcheckIfHeader2HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.taxCodeMappings && Object.keys(formik.errors?.taxCodeMappings as any).length > 0) &&
                (formik.touched?.taxCodeMappings && Object.keys(formik.touched?.taxCodeMappings as any).length > 0))) {
                if (activeTabs.includes(2))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(2);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader3HasError(true);
            }
            else {
                setcheckIfHeader3HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.paymentMapping && Object.keys(formik.errors?.paymentMapping as any).length > 0) &&
                (formik.touched?.paymentMapping && Object.keys(formik.touched?.paymentMapping).length > 0))) {
                if (activeTabs.includes(3))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(3);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader4HasError(true);
            }
            else {
                setcheckIfHeader4HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.productMapping && Object.keys(formik.errors?.productMapping as any).length > 0) &&
                (formik.touched?.productMapping && Object.keys(formik.touched?.productMapping).length > 0))) {
                if (activeTabs.includes(4))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(4);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader5HasError(true);
            }
            else {
                setcheckIfHeader5HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.productStockMapping && Object.keys(formik.errors?.productStockMapping as any).length > 0) &&
                (formik.touched?.productStockMapping && Object.keys(formik.touched?.productStockMapping).length > 0))) {
                if (activeTabs.includes(5))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(5);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader6HasError(true);
            }
            else {
                setcheckIfHeader6HasError(false);
            }

            if (formik.submitCount > 0) {
                const validatedWarehouseMappings = validateWarehouseMappings(warehouseMappings ? warehouseMappings : []);
                setWarehoueMappings(validatedWarehouseMappings);

                let hasError = validatedWarehouseMappings.some((x) => x.isError === true);
                if (hasError) {
                    if (activeTabs.includes(6))
                        setActiveTabs(activeTabs);
                    else {
                        activeTabs.push(6);
                        setActiveTabs(activeTabs);
                    }
                    setcheckIfHeader7HasError(true);
                } else {
                    setcheckIfHeader7HasError(false);
                }
            }

            // if (formik.submitCount > 0 && ((formik.errors?.warehouseMappings && Object.keys(formik.errors?.warehouseMappings as any).length > 0) &&
            //     (formik.touched?.warehouseMappings && Object.keys(formik.touched?.warehouseMappings).length > 0))) {
            //     if (activeTabs.includes(6))
            //         setActiveTabs(activeTabs);
            //     else {
            //         activeTabs.push(6);
            //         setActiveTabs(activeTabs);
            //     }
            //     setcheckIfHeader7HasError(true);
            // }
            // else {
            //     setcheckIfHeader7HasError(false);
            // }

            if (formik.submitCount > 0 && ((formik.errors?.myobCustomerToNeto && Object.keys(formik.errors?.myobCustomerToNeto as any).length > 0) &&
                (formik.touched?.myobCustomerToNeto && Object.keys(formik.touched?.myobCustomerToNeto).length > 0))) {
                if (activeTabs.includes(7))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(7);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader8HasError(true);
            }
            else {
                setcheckIfHeader8HasError(false);
            }

            if (formik.submitCount > 0 && ((formik.errors?.mapExistingData && Object.keys(formik.errors?.mapExistingData as any).length > 0) &&
                (formik.touched?.mapExistingData && Object.keys(formik.touched?.mapExistingData).length > 0))) {
                if (activeTabs.includes(8))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(8);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader9HasError(true);
            }
            else {
                setcheckIfHeader9HasError(false);
            }
        }
        else {
            setcheckIfHeader1HasError(false);
            setcheckIfHeader2HasError(false);
            setcheckIfHeader3HasError(false);
            setcheckIfHeader4HasError(false);
            setcheckIfHeader5HasError(false);
            setcheckIfHeader6HasError(false);
            // setcheckIfHeader7HasError(false);
            if (formik.submitCount > 0) {
                const validatedWarehouseMappings = validateWarehouseMappings(warehouseMappings ? warehouseMappings : []);
                setWarehoueMappings(validatedWarehouseMappings);

                let hasError = validatedWarehouseMappings.some((x) => x.isError === true);
                if (hasError) {
                    if (activeTabs.includes(6))
                        setActiveTabs(activeTabs);
                    else {
                        activeTabs.push(6);
                        setActiveTabs(activeTabs);
                    }
                    setcheckIfHeader7HasError(true);
                } else {
                    setcheckIfHeader7HasError(false);
                }
            }
            setcheckIfHeader8HasError(false);
            setcheckIfHeader9HasError(false);
        }
    }, [formik.touched, formik.errors]);

    useEffect(() => {
        if (!formik.isValid) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: "There are some validation errors!" } });
        }

        const validatedWarehouseMappings = validateWarehouseMappings(warehouseMappings ? warehouseMappings : []);
        setWarehoueMappings(validatedWarehouseMappings);

        let hasError = validatedWarehouseMappings.some((x) => x.isError === true);
        if (hasError) {
            if (activeTabs.includes(6))
                setActiveTabs(activeTabs);
            else {
                activeTabs.push(6);
                setActiveTabs(activeTabs);
            }
            setcheckIfHeader7HasError(true);
        } else {
            setcheckIfHeader7HasError(false);
        }
    }, [formik.submitCount]);

    const populateWarehouse = (isSetInFormikValues?: boolean, netoWarehouses?: any) => {
        let warehouseMappingArr: any = [];

        if (isSetInFormikValues) {
            warehouseMappingArr = evaluateWarehouseMappings(netoWarehouses, State.userMappings?.warehouseMapping, warehouseMappings ? warehouseMappings : []); //formik.values.warehouseMappings)
            setWarehoueMappings(warehouseMappingArr); //formik.setFieldValue("warehouseMappings", warehouseMappingArr, true);
        } else {
            warehouseMappingArr = evaluateWarehouseMappings(State.formsLookup?.warehouseMapping?.netoWarehouses, State.userMappings?.warehouseMapping, warehouseMappings ? warehouseMappings : []);
            setWarehoueMappings(warehouseMappingArr);
        }
        return warehouseMappingArr;
    }

    const refreshNetoToMyobFormLookups = async () => {
        setRefreshLookupState(true);
        // resetFormikValuesInLocalStates();

        const netoToMyobFormLookupsRequestResult = await getFormLookupsNetoToMyobMappings(/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId, id);
        if (netoToMyobFormLookupsRequestResult?.isSucceed) {
            Dispatch({
                Type: "SET_FORMSLOOKUP",
                Payload: netoToMyobFormLookupsRequestResult?.result,
            });

            // if (netoToMyobFormLookupsRequestResult?.result?.warehouseMapping?.netoWarehouses && netoToMyobFormLookupsRequestResult?.result?.warehouseMapping?.netoWarehouses.length > 0) {
            // if (warehouseMappings && warehouseMappings.length < netoToMyobFormLookupsRequestResult?.result?.warehouseMapping?.netoWarehouses.length) {
            populateWarehouse(true, netoToMyobFormLookupsRequestResult?.result?.warehouseMapping?.netoWarehouses);
            //     }
            // }

            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Lookups refreshed!" } });
            setRefreshLookupState(false);
        }
        else {
            setRefreshLookupState(false);
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: netoToMyobFormLookupsRequestResult?.message } });
        }
    }

    const resetFormikValuesInLocalStates = () => {
        setSalesOrderMapping(formik.values.salesOrderMapping);
        setSyncFeatures(formik.values.synchronizationFeatures);
        setTaxCodeMapping(formik.values.taxCodeMappings);
        setPaymentMapping(formik.values.paymentMapping);
        setProductMapping(formik.values.productMapping);
        setProductStockMapping(formik.values.productStockMapping);
        setMyobCustomerToNeto(formik.values.myobCustomerToNeto);
        setMapExistingData(formik.values.mapExistingData);
    }

    const headerToolbarRightContents = (
        <React.Fragment>
            <Button icon="pi pi-angle-left" label="Back" type="button" className="mt-1 p-button-raised p-button-secondary p-button-sm" onClick={() => history.goBack()} />
            <Button
                className="btn ml-1 mt-1 p-button-raised p-button-warning p-button-sm"
                style={{ width: "15rem" }}
                disabled={refreshLookupState}
                type='button'
                onClick={() => refreshNetoToMyobFormLookups()}>
                Refresh  <FontAwesomeIcon className="ml-1" spin={refreshLookupState} icon={faSync} />
            </Button>
        </React.Fragment>
    );

    const headerToolbarLeftContents = (
        <React.Fragment>
            <h5>Toolbar</h5>
        </React.Fragment>
    );

    const footerToolbarLeftContents = (
        <React.Fragment>
            <h5>Actions</h5>
        </React.Fragment>
    );

    const footerToolbarRightContents = (
        <React.Fragment>
            <p className="text-muted m-3">
                Last modified on {lastModifiedDataOfMapping?.lastModified ? lastModifiedDataOfMapping?.lastModified : lastModifiedDateOfMapping} by {lastModifiedDataOfMapping?.modifiedByEmail ? lastModifiedDataOfMapping?.modifiedByEmail?.substring(0, lastModifiedDataOfMapping?.modifiedByEmail?.lastIndexOf("@")) : "You"}
            </p>
            <Button
                className="btn mt-1 p-button-raised p-button-info p-button-sm"
                style={{ width: "15rem" }}
                type="submit">
                Save Mappings
            </Button>
        </React.Fragment>
    );

    const mappingHeaders = {
        header1: headerTemplate("Map Maropost Sales Order To MYOB Invoice", checkIfHeader1HasError),
        header2: headerTemplate("Syncronization Features", checkIfHeader2HasError),
        header3: headerTemplate("Map Tax Code", checkIfHeader3HasError, "Maropost To MYOB"),
        header4: headerTemplate("Map Payment", checkIfHeader4HasError, "Maropost To MYOB"),
        header5: headerTemplate("Map Maropost Product To MYOB Item", checkIfHeader5HasError),
        header6: headerTemplate("Map Product Stock", checkIfHeader6HasError, "MYOB To Maropost"),
        header7: headerTemplate("Map Warehouses", checkIfHeader7HasError),
        header8: headerTemplate("Map Customer", checkIfHeader8HasError),
        header9: headerTemplate("Map Existing Data", checkIfHeader9HasError),
    }

    const saveMappings = async () => {
        setBlockMappings(true);

        let mappings: UserMappingModel = {
            salesOrderMapping: {} as SalesOrderMappingsModel,
            synchronizationFeatures: {} as SynchronizationFeaturesMappingsModel,
            taxCodeMappings: {} as TaxCodeMappingsModel,
            paymentMapping: {} as PaymentMappingsModel,
            productStockMapping: {} as ProductStockMappingsModel,
            itemMapping: {} as ItemMappingsModel,
            existingData: {} as ExistingDataMappingsModel,
            netoCustomerToMyob: {} as CustomerMappingsNetoToMyobModel,
            myobCustomerToNeto: {} as CustomerMappingsMyobToNetoModel,
            warehouseMapping: [],
            SyncProcessingStates: getMappingsInitialValues().SyncProcessingStates as SyncProcessingStatesModel
        }

        if (formik.values.salesOrderMapping?.assignCustomer === "GenericOnlineSaleCustomer" && _.isEmpty(formik.values.salesOrderMapping.defaultOnlineCustomerValue)) {
            let salesMapping = { ...formik.values.salesOrderMapping };
            salesMapping.defaultOnlineCustomerValue = storeName;
            mappings.salesOrderMapping = salesMapping;
        }
        else if (formik.values.salesOrderMapping) {
            mappings.salesOrderMapping = formik.values.salesOrderMapping;
        }

        mappings.synchronizationFeatures = formik.values.synchronizationFeatures ? formik.values.synchronizationFeatures : {} as SynchronizationFeaturesMappingsModel;
        mappings.taxCodeMappings = formik.values.taxCodeMappings ? formik.values.taxCodeMappings : {} as TaxCodeMappingsModel;
        mappings.paymentMapping = formik.values.paymentMapping ? formik.values.paymentMapping : {} as PaymentMappingsModel;
        mappings.productStockMapping = formik.values.productStockMapping ? formik.values.productStockMapping : {} as ProductStockMappingsModel;
        mappings.netoCustomerToMyob = formik.values.netoCustomerToMyob;
        mappings.myobCustomerToNeto = formik.values.myobCustomerToNeto;
        mappings.existingData = formik.values.mapExistingData ? formik.values.mapExistingData : {} as ExistingDataMappingsModel;
        mappings.itemMapping = formik.values.productMapping ? formik.values.productMapping : {} as ItemMappingsModel;

        if (warehouseMappings) {
            const validatedWarehouseMappings = validateWarehouseMappings(warehouseMappings ? warehouseMappings : []);
            let hasError = validatedWarehouseMappings.some((x) => x.isError === true);
            if (hasError) {
                if (activeTabs.includes(6))
                    setActiveTabs(activeTabs);
                else {
                    activeTabs.push(6);
                    setActiveTabs(activeTabs);
                }
                setcheckIfHeader7HasError(true);
                setBlockMappings(false);
                return;
            } else {
                setcheckIfHeader7HasError(false);
                mappings.warehouseMapping = warehouseMappings.map((w: any) => (
                    {
                        Identifier: w.identifier ?? '',
                        myobLocationId: w.selectedValue ?? '',
                        myobLocationName: w.selectedName ?? '',
                        netoWarehouseId: w.warehouse.value ?? '',
                        netoWarehouseName: w.warehouse.label ?? ''
                    }));
            }
        }
        else {
            mappings.warehouseMapping = [];
        }

        let lastModifiedData = {
            lastModified: moment(new Date).format("DD MMM yyyy hh:mm A"),
            // modifiedByUserId: State.currentUser.currentUserId,
            modifiedByEmail: State.currentUser.currentUserEmail,
        };

        let result = await saveUserMappings(State.currentUser.currentUserId, id, mappings, lastModifiedData);
        if (result.isSucceed) {
            Dispatch({ Type: "SET_USER_MAPPINGS", Payload: mappings });
            setBlockMappings(false);
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result.message } });
            setIsMappinsEmpty(false);
            writeInfo(`Message : 'Mappings has been saved Successfully', UserId: ${State.currentUser.currentUserId}, ConnectorId: ${id}, Action-DateTime:${moment().format("DD MMM yyyy hh:mm A")}`);
        }
        else {
            setIsMappinsEmpty(true);
            setBlockMappings(false);
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result.message } });
        }
    }

    return (
        <div>
            <BlockUI id='blockUi' key='blockuiKey' onBlocked={() => setBlockMappings(true)} onUnblocked={() => setBlockMappings(false)} blocked={blockMappings} template={<ProgressSpinner />}>
                <Panel>
                    <FormikProvider value={formik}>
                        <Form>
                            <div className="divSticky">
                                <Toolbar left={headerToolbarLeftContents} right={headerToolbarRightContents} />
                            </div>
                            <br />
                            <Accordion multiple activeIndex={activeTabs}>
                                <AccordionTab header={mappingHeaders.header1}>
                                    <NetoSalesOrderToMYOBInvoiceBasic
                                        salesOrderMappings={formik.values.salesOrderMapping ? formik.values.salesOrderMapping : {} as SalesOrderMappingsModel}
                                        setSalesOrderMappings={(output: any) => {
                                            formik.setFieldValue("salesOrderMapping", output, true);
                                            // setSalesOrderMapping(output);
                                        }}
                                        storeName={storeName}
                                        isMappingsEmpty={isMappingsEmpty}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                        submitCount={formik.submitCount}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header2}>
                                    <SyncronizationFeatures
                                        syncFeatures={formik.values.synchronizationFeatures ? formik.values.synchronizationFeatures : {} as SynchronizationFeaturesMappingsModel}
                                        setSyncFeatures={(output: SynchronizationFeaturesMappingsModel) => {
                                            formik.setFieldValue("synchronizationFeatures", output, true);
                                            // setSyncFeatures(output);
                                        }}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                        isMappingsEmpty={isMappingsEmpty}
                                        submitCount={formik.submitCount}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header3}>
                                    <NetoToMYOBTaxCodeMapping
                                        taxCodeMapping={formik.values.taxCodeMappings ? formik.values.taxCodeMappings : {} as TaxCodeMappingsModel}
                                        setTaxCodeMapings={(output: any) => {
                                            formik.setFieldValue("taxCodeMappings", output, true);
                                            // setTaxCodeMapping(output);
                                        }}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                        submitCount={formik.submitCount}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header4}>
                                    <NetoToMYOBPaymentMapping
                                        paymentMapping={formik.values.paymentMapping ? formik.values.paymentMapping : {} as PaymentMappingsModel}
                                        setPaymentMappings={(output: any) => {
                                            formik.setFieldValue("paymentMapping", output, true);
                                            // setPaymentMapping(output);
                                        }}
                                        isMappingsEmpty={isMappingsEmpty}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header5}>
                                    <NetoProductToMYOBItemMapping
                                        productMapping={formik.values.productMapping ? formik.values.productMapping : {} as ItemMappingsModel}
                                        setProductMapping={(output: any) => {
                                            formik.setFieldValue("productMapping", output, true);
                                            // setProductMapping(output);
                                        }}
                                        isMappingsEmpty={isMappingsEmpty}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                        submitCount={formik.submitCount}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header6}>
                                    <MYOBToNetoProductStockMapping
                                        productStockMapping={formik.values.productStockMapping ? formik.values.productStockMapping : {} as ProductStockMappingsModel}
                                        setProductStockMappings={(output: any) => {
                                            formik.setFieldValue("productStockMapping", output, true);
                                            // setProductStockMapping(output);
                                        }}
                                        isMappingsEmpty={isMappingsEmpty}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header7}>
                                    <WarehouseMapping
                                        warehouseMappings={/*formik.values.warehouseMappings ? formik.values.*/warehouseMappings ?? [] as Array<WarehouseCommunicationModel>}
                                        // onChange={(value: any) => {
                                        //     // formik.setFieldValue("warehouseMappings", value, true);
                                        //     setWarehoueMappings(value);
                                        // }}
                                        // errors={formik.errors}
                                        // touched={formik.touched}
                                        onInputBlurred={onWarehouseBlur} //onInputBlurred}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header8}>
                                    <CustomerMapping
                                        myobCustomerToNeto={formik.values.myobCustomerToNeto}
                                        setMyobCustomerToNeto={(output: any) => {
                                            formik.setFieldValue("myobCustomerToNeto", output, true);
                                            // setMyobCustomerToNeto(output);
                                        }}
                                        isMappingsEmpty={isMappingsEmpty}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                    />
                                </AccordionTab>
                                <AccordionTab header={mappingHeaders.header9}>
                                    <ExistingData
                                        mapExistingData={formik.values?.mapExistingData ? formik.values?.mapExistingData : {} as ExistingDataMappingsModel}
                                        setExistingData={(output: any) => {
                                            formik.setFieldValue("mapExistingData", output, true);
                                        }}
                                        errors={formik.errors}
                                        isMappingsEmpty={isMappingsEmpty}
                                        touched={formik.touched}
                                        onInputBlurred={onInputBlurred}
                                        submitCount={formik.submitCount}
                                    />
                                </AccordionTab>
                            </Accordion>
                            <br />
                            <div className="btnSticky">
                                <Toolbar left={footerToolbarLeftContents} right={footerToolbarRightContents} />
                            </div>
                        </Form>
                    </FormikProvider>
                </Panel>
            </BlockUI>
        </div>
    )
}

export default NetoToMyobMappingsContainer

