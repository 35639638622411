import firebase from "firebase";
import * as React from "react";
import { useEffect, useState } from "react";
import { Card } from "primereact/card";

import Label from "../components/Label";
import SelectInput from "../components/SelectInput";
import { ValueLabel } from "../shared/models";
import { AppContext } from "../store/AppContext";
import { getAllConnectorLabels } from "../features/Order/services/OrderService";
import _ from "lodash";
import { convertLocalDate } from "../shared/Utilities";
import { UserMappingModel } from "../shared/models/mappings/UserMappingModels";

const UserInfoPage: React.FC = () => {
  let { Dispatch } = React.useContext(AppContext);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [syncProcessingStates, setSyncProcessingStates] = useState<any>();
  const [mappingsData, setMappingData] = useState<UserMappingModel>({} as UserMappingModel);
  const [lastModifiedDataOfMapping, setLastModifiedDataOfMapping] = useState<any>();
  const [lastModifiedDateOfMapping, setLastModifiedDateOfMapping] = useState<any>();
  const [orderCounters, setOrderCounters] = useState<any>({});
  const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" },]);
  const [selectedConnector, setSelectedConnector] = useState<string>("");

  useEffect(() => {
    firebase.firestore().collection("user-details").get()
      .then((data) => { setUsersData(data?.docs?.map((doc) => doc?.data())); });
    return () => {
      setConnectors([]);
      setSyncProcessingStates({});
      setMappingData({} as UserMappingModel);
      setLastModifiedDataOfMapping("");
      setOrderCounters({});
    }
  }, []);


  useEffect(() => {
    const getConnector = async () => {
      const connectorsList = await getAllConnectorLabels(selectedUser.userId);
      setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
      setConnectors(connectorsList);
      setSyncProcessingStates({});
      setMappingData({} as UserMappingModel);
      setLastModifiedDataOfMapping("");
      setOrderCounters({});
    };
    selectedUser && getConnector();
  }, [selectedUser]);

  useEffect(() => {
    const getConnector = async () => {
      const ref = firebase.firestore().collection("user-details").doc(selectedUser.userId).collection(`connectors`).doc(selectedConnector);
      ref.onSnapshot((snapshot: any) => {
        const snapshotData = snapshot?.data();
        setSyncProcessingStates(snapshotData?.mappings?.SyncProcessingStates ?? {});
        setMappingData(snapshotData?.mappings ?? {});
        setLastModifiedDataOfMapping(snapshotData?.lastModifiedData ?? {});
        setLastModifiedDateOfMapping(snapshotData?.lastModified ?? "");
      });
      ref.collection("order-counter").doc("orderCounter").onSnapshot((snapshot: any) => {
        setOrderCounters(snapshot.data());
      });
    };
    selectedConnector && getConnector();
  }, [selectedConnector]);

  const formatCamelCaseToReadable = (text: string) => {
    return text
      ?.replace("LastStartedAt", '')
      // Add space before each uppercase letter
      ?.replace(/([a-z])([A-Z])/g, '$1 $2')
      // Capitalize the first letter of each word
      ?.replace(/^./, str => str?.toUpperCase())
      ?? "";
  }
  return (
    <>
      <div className="container">
        <div className="row mt-5 text-left">
          <div className="col-3">
            <Label text={"Select User: "} />
          </div>
          <div className="col-9">
            {usersData && (
              <SelectInput options={usersData} selectedOptionValue={selectedUser} optionLabel="email" showClear
                onChange={(e: any) => {
                  setSelectedConnector("");
                  setSelectedUser(e);
                }}
              />
            )}
          </div>
        </div>

        {selectedUser && (
          <div className="row mt-1 text-left">
            <div className="col-3">
              <Label text={"Select Connector: "} />
            </div>
            <div className="col-9">
              <SelectInput
                options={connectors}
                selectedOptionValue={selectedConnector}
                onChange={(connector: string) => {
                  Dispatch({
                    Type: "SET_SELECTED_CONNECTOR",
                    Payload: {
                      id: connector,
                    },
                  });
                  setSelectedConnector(connector);
                }}
              />
            </div>
          </div>
        )}

        <div className="mt-5 container d-flex justify-content-center">
          {selectedUser ? <div className="row">
            <Card className="col shadow-lg">
              <div className="m-2">
                <p className="text-muted h5 text-capitalize ">
                  <ins>User Info</ins>
                </p>
                <div className="text-left mt-3">
                  <small className="mt-5">
                    <span className="font-weight-bold"> Email:</span>
                    {selectedUser?.email}
                  </small>
                  <br />
                  <small className="mt-5">
                    <span className="font-weight-bold"> UserId:</span>
                    {selectedUser?.userId}
                  </small>
                  <br />
                  <small className="mt-5">
                    <span className="font-weight-bold"> ConnectorId:</span>
                    {selectedConnector}
                  </small>
                  <br />
                  <small className="mt-5">
                    <span className="font-weight-bold">isActive:</span>
                    {selectedUser?.isActive ? "Yes" : "No"}
                  </small>
                  <br />
                  <small className="mt-5">
                    <span className="font-weight-bold">isAddonUser:</span>
                    {selectedUser?.isAddonUser ? "Yes" : "No"}
                  </small>
                </div>
              </div>
            </Card>
            <div className="col-1"></div>
            <Card className="col shadow-lg">
              <div className="m-2">
                <p className="text-muted h5 text-capitalize ">
                  <ins>Mapping Last Modified Date</ins>
                </p>
                <h4 className="my-5">{lastModifiedDataOfMapping?.lastModified ?? lastModifiedDateOfMapping}</h4>
                {lastModifiedDataOfMapping?.modifiedByEmail &&
                  <small className="mt-3">
                    <span className="font-weight-bold"> Email:</span>
                    {lastModifiedDataOfMapping?.modifiedByEmail}
                  </small>
                }
                <br />
                {/* {lastModifiedDataOfMapping?.modifiedByUserId&&
                  <small >
                    <span className="font-weight-bold">UserId:</span>
                    {lastModifiedDataOfMapping?.modifiedByUserId}
                  </small>
                  } */}
                {/* <h4 className="my-5">{lastModifiedDateOfMapping.modifiedByEmail ?? ""}</h4>
                <h4 className="my-5">{lastModifiedDateOfMapping.modifiedByUserId ?? ""}</h4> */}
              </div>
            </Card>
            <div className="col-1"></div>
            <Card className="col shadow-lg">
              <div className="m-2">
                <p className="text-muted h5 text-capitalize">
                  <ins>Orders Counter</ins>
                </p>
                <div className="text-left">
                  <div className="d-flex justify-content-between mt-5">
                    <h6 className="text-success">Exported Complete: </h6>
                    <span>{orderCounters?.ExportComplete ?? 0}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-danger">Exported Failed: </h6>
                    <span>{orderCounters?.ExportFailed ?? 0}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-primary">Not Processed: </h6>
                    <span>{orderCounters?.NotProcessed ?? 0}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-danger">Imported: </h6>
                    <span>{orderCounters?.Imported ?? 0}</span>
                  </div>
                </div>
              </div>
            </Card>
            <Card className="col-12 mt-5 shadow-lg">
              <p className="text-muted h5 text-capitalize ">
                <ins>Sync Process Status & Dates </ins>
              </p>
              {!_.isEmpty(syncProcessingStates) ? (
                <div className="text-left ml-5">
                  <div className="row">
                    <div className="col-3">
                      <h5 className="my-5">Sync Process States</h5>
                      {Object.keys(syncProcessingStates)?.map((key, i) => (
                        <>
                          {_.isBoolean(syncProcessingStates[key]) && (
                            <p className="row" key={i}>
                              <span className="h6">
                                {_.isBoolean(syncProcessingStates[key]) && (
                                  <span className="h6 font-weight-bold">
                                    - {formatCamelCaseToReadable(key)}
                                  </span>
                                )}
                              </span>
                              <span className="ml-3">
                                {_.isBoolean(syncProcessingStates[key]) &&
                                  (syncProcessingStates[key] == false ? (
                                    <span className="h6 text-danger">
                                      Not Running
                                    </span>
                                  ) : (
                                    <span className="h6 text-success">
                                      Running
                                    </span>
                                  ))}
                              </span>
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="col-4">
                      <h5 className="my-5">Start Time Of Sync Process</h5>
                      {Object.keys(syncProcessingStates).map((key, i) => (
                        <>
                          {!_.isEmpty(syncProcessingStates[key]) && (
                            <p className="row" key={i}>
                              <span className="h6">
                                <span className="h6 font-weight-bold">
                                  - {formatCamelCaseToReadable(key)}
                                </span>
                              </span>
                              <span className="h6 ml-3" >
                                <span className="h6">
                                  {syncProcessingStates[key] ? convertLocalDate(syncProcessingStates[key]) : "---"}
                                </span>
                              </span>
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="col-4">
                      <h5 className="my-5">End Time Of Sync Process</h5>
                      {!_.isEmpty(mappingsData) && (
                        <>
                          {!_.isEmpty(mappingsData?.salesOrderMapping?.lastRunAt) && <p className="row">
                            <span className="h6">
                              <span className="h6 font-weight-bold">
                                - Payment MYOB To Neto/Maropost
                              </span>
                            </span>
                            <span className="ml-3">
                              <span className="h6">
                                {!_.isEmpty(mappingsData?.salesOrderMapping?.lastRunAt) ? convertLocalDate(mappingsData?.salesOrderMapping?.lastRunAt) : "---"}
                              </span>
                            </span>
                          </p>}
                          {!_.isEmpty(mappingsData?.paymentMapping?.lastRunAt) && <p className="row">
                            <span className="h6">
                              <span className="h6 font-weight-bold">
                                - Payment Neto/Maropost to MYOB
                              </span>
                            </span>
                            <span className="ml-3">
                              <span className="h6">
                                {!_.isEmpty(mappingsData?.paymentMapping?.lastRunAt) ? convertLocalDate(mappingsData?.paymentMapping?.lastRunAt) : "---"}
                              </span>
                            </span>
                          </p>}
                          {!_.isEmpty(mappingsData?.salesOrderMapping?.rmaLastRunAt) && <p className="row">
                            <span className="h6">
                              <span className="h6 font-weight-bold">
                                - RMA Neto/Maropost To MYOB
                              </span>
                            </span>
                            <span className="ml-3">
                              <span className="h6">
                                {!_.isEmpty(mappingsData?.salesOrderMapping?.rmaLastRunAt) ? convertLocalDate(mappingsData?.salesOrderMapping?.rmaLastRunAt) : "---"}
                              </span>
                            </span>
                          </p>}

                          {!_.isEmpty(mappingsData?.productStockMapping?.lastRunAt) && <p className="row">
                            <span className="h6">
                              <span className="h6 font-weight-bold">
                                - Product MYOB To Neto/Maropost
                              </span>
                            </span>
                            <span className="ml-3">
                              <span className="h6">
                                {!_.isEmpty(mappingsData?.productStockMapping?.lastRunAt) ? convertLocalDate(mappingsData?.productStockMapping?.lastRunAt) : "---"}
                              </span>
                            </span>
                          </p>}

                          {!_.isEmpty(mappingsData?.itemMapping?.lastRunAt) && <p className="row">
                            <span className="h6">
                              <span className="h6 font-weight-bold">
                                - Product Neto/Maropost To MYOB
                              </span>
                            </span>
                            <span className="ml-3">
                              <span className="h6">
                                {!_.isEmpty(mappingsData?.itemMapping?.lastRunAt) ? convertLocalDate(mappingsData?.itemMapping?.lastRunAt) : "---"}
                              </span>
                            </span>
                          </p>}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!_.isEmpty(selectedConnector) && (
                    <h3 className="mt-4">No Sync Processing Data Found </h3>
                  )}
                </>
              )}
            </Card>
          </div> : <>
            <Card className="col shadow-lg">
              <div className="m-2">
                <p className="text-muted h5 text-capitalize">
                  Select any user and connector
                </p>
              </div>
            </Card>
          </>}
        </div>
      </div>
    </>
  );
};

export default UserInfoPage;
